import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import KioskStart from './pages/KioskStartPage';
import KioskScan from './pages/KioskQrScanPage';
import KioskTicket from './pages/KioskTicketPage';
import QrReaderPage from './pages/QrReaderPage';
import KioskScanSuccess from './pages/KioskQrSuccessPage';
import KioskScanFail from './pages/KioskQrFailPage';

function App() {
  return (
    <div className="App text-middel">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<KioskStart />} />
            <Route path="/kiosk-start" element={<KioskStart />} />
            <Route path="/kiosk-scan" element={<KioskScan />} />
            <Route path="/kiosk-scan-success" element={<KioskScanSuccess />} />
            <Route path="/kiosk-scan-fail" element={<KioskScanFail />} />
            <Route path="/kiosk-ticket" element={<KioskTicket />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
