import React from 'react';
import { Link } from 'react-router-dom';

const KioskStart = () => {


  return (
    <Link to="/kiosk-scan" >
      <div className="inner-section-kiosk">
        <div className='home-page'>
          <div className='logo-div'>
            {/* <img className="logo-img" src="/images/home-img-removebg-preview.png" /> */}
            <video className="video-tag-starting" width="100%" height="100%" autoplay="autoplay" loop muted={true}>
              <source src="/images/JamboFoodMarkt_01.mp4" type="video/mp4" />
            </video>
          </div>
          {/* <div>
            <img className='sub-logo' src="/images/img2.png" />
            <img className='handarrow-logo' src="/images/img3.png" />
          </div> */}
          {/* <div className='btm-img-div'>
            <img src="../images/jum-logo.png" alt="" className="img-fluid btm-img" />
          </div> */}
        </div>
      </div>
    </Link>
  );
};

export default KioskStart;