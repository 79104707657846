import React from "react";

export const WeekDealDetails = ({weekDeal}) => {
    return (
        <div className="instructions padding-zero col-4 justify-content-center">
            <div className="success-content-sec">
                <div className="succ-text-sec">
                    <p className="text-succ">GEWELDIG!<br/> JE JUMBO DEAL IS</p>
                </div>
                <div className="succ-img-div">
                    <img className="succ-card-img img-fluid" src={weekDeal.image} alt=""/>

                </div>

            </div>

        </div>
    )
}