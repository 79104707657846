import React, {useState} from "react";
import {LocalStorage} from "../LocalStorage.ts";
import {useNavigate} from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {Spinner} from "./Spinner.tsx";

export const PrinterEmpty = () => {
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
  return (
      <div className="content1">
        <div className="prize-content-sec">
          <button style={{color: "white", backgroundColor: "black", paddingTop:4, paddingBottom: 4, paddingLeft: 25, paddingRight: 25, border: 1, borderRadius: 4}}
          onClick={
            () => {
              const pin = prompt('Please enter the pin');
              if (pin === '9000'){
                  setLoading(true)
                  fetch(process.env.REACT_APP_API_URL + "printer-refill", {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ remaining_pages: LocalStorage.getPages() })
                  }).then(res => res.json()).then((res) => {
                      LocalStorage.refillPages();
                      setLoading(false)
                      window.location.href = "/kiosk-start";
                  }).catch(error=> {
                      console.error(error)
                      setLoading(false)
                  })
              }else {
                alert("Wrong Pin!");
              }
            }
          }
          >
            PAPIER BIJGEVULD {isLoading && <Spinner /> }
          </button>
        </div>

        <img className="eye-logo" src="/images/eye.png"/>
        <div className="position-relative div-img-width">
          <img className="water-melon-logo" src="/images/watermelon.png"/>
        </div>

        {/* <img className='shadow-logo' src="/images/shadow.png" /> */}
      </div>
  );
};

export const PrinterEmptyHeader = () => {
  return (
      <>
        <div className=" heading-section text-center">
          {/* <img className='img-fluid thankx-img' src="/images/logo2.png" /> */}
        <div className="head-div d-flex justify-content-center align-items-center">
          <div className="butm-jumb-img">
            <img
              className="img-fluid btm-logo-img"
              src="/images/Group-487.svg"
              alt=""
            />
          </div>
          <div className="butm-jumb-img food-markt-img">
            <img
              className="img-fluid btm-img-food"
              src="/images/Group-485.svg"
              alt=""
            />
          </div>
        </div>
        <div className="prize-content-sec">
          <p className="prize-name">
            IK HEB PAPIER NODIG!
          </p>
        </div>
      </div>
      {/* <div className=" heading-section text-center">
        <img className="img-fluid thankx-img" src="/images/logo2.png" />
      </div> */}
    </>
  );
};
