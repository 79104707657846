import React from "react";

export const PrintPage = () => {
  return (
    <div className="content1">
      <img className="eye-logo" src="/images/eye.png" />
      <div className="position-relative div-img-width">
        <img className="water-melon-logo" src="/images/watermelon.png" />
      </div>

      {/* <img className='shadow-logo' src="/images/shadow.png" /> */}
    </div>
  );
};

export const PrintPageHeader = () => {
  return (
    <>
      <div className=" heading-section text-center">
        {/* <img className='img-fluid thankx-img' src="/images/logo2.png" /> */}
        <div className="head-div d-flex justify-content-center align-items-center">
          <div className="butm-jumb-img">
            <img
              className="img-fluid btm-logo-img"
              src="/images/Group-487.svg"
              alt=""
            />
          </div>
          <div className="butm-jumb-img food-markt-img">
            <img
              className="img-fluid btm-img-food"
              src="/images/Group-485.svg"
              alt=""
            />
          </div>
        </div>
        <div className="prize-content-sec">
          <h2 className="prize-head">NEEM JE TICKET</h2>
          <p className="prize-name">
            <span className="text-white">VEEL</span> JUMBO SHOP{" "}
            <span className="text-white"> PLEZIER!</span>
          </p>
        </div>
      </div>
      {/* <div className=" heading-section text-center">
        <img className="img-fluid thankx-img" src="/images/logo2.png" />
      </div> */}
    </>
  );
};
