import React from "react";

export const ScanFailed = () => {
    return (
        <div className="instructions padding-zero col-4 justify-content-center">
            <div className="fail-content-sec">
                <div className="succ-text-sec">
                    <p className="text-succ">SORRY, JE CODE WERD REEDS GEBRUIKT </p>
                </div>
            </div>
        </div>
    )
}

export const RedeemedFailed = () => {
    return  (
        <div className="instructions padding-zero col-4 justify-content-center">
            <div className="fail-content-sec">
                <div className="succ-text-sec">
                    <p className="text-succ">SORRY, PROBEER HET NOG EENS </p>
                </div>
            </div>
        </div>
    )
}