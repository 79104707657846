import {React} from 'react';

function QrReaderPage() {
    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
    };

    return (
        <div className="App">
            {/*<Scanner onScan={(result) => console.log(result)} />*/}
        </div>
    );
}
export default QrReaderPage;
