
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingPrinter from '../components/LoadingPrinter.tsx'
import BarcodeImage from '../assets/img/barcode.jpg';
import QrReader from "../components/QrReader";
import QrScanner from "qr-scanner";
import "../components/QrStyles.css";
import {PrinterContext, PrinterState} from "../context/PrinterContext";
import { ScanProcess } from "../components/ScanProcess";
import { WeekDealDetails } from "../components/WeekDealDetails";
import { RedeemedFailed, ScanFailed } from "../components/ScanFailed";
import { PrizeDetails } from "../components/PrizeDetails";
import { PrintPage, PrintPageHeader } from "../components/PrintPage";
import {PrinterEmpty, PrinterEmptyHeader} from "../components/PrinterEmpty";
const QrFrame = require("../assets/qr-frame.svg").default;

const RewardType = {
  WEEK_DEAL: "week_deal",
  PRIZE: "prize"
}

const ScannerState = {
  IDEAL: "IDEAL",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  REDEEMFAILED: "REDEEMFAILED"
}
const KioskScan = () => {
  const navigate = useNavigate();
  const timer = useRef(null)
  const { printerState, statusMessage, connectPrinter, disconnectPrinter, printWithEpson, printText } = useContext(PrinterContext);
  const [scannerState, setScannerState] = useState(ScannerState.IDEAL);
  const [weekDeal, setWeekDeal] = useState(null)
  const [prize, setPrize] = useState(null)
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  // const [printerState, setPrinterState] = useState(PrinterState.CONNECTING)


  const fetchBarCodeDetails = useCallback((qr_data) => {
    scanner?.current?.stop()
    console.info({ qr_data })
    const _pState = printerState;
    fetch(process.env.REACT_APP_API_URL + "scan-qrcode", {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ qr_key: qr_data.data })
    }).then(res => res.json())
      .then((response) => {
        console.info({ response })
        if (response.success === true) {
          setScannerState(ScannerState.SUCCESS);
          const details = response.data;
          console.info({ printerState, _pState })
          if (details.reward_type === RewardType.PRIZE) {
            setPrize(details.prize);
          } else {
            //handle week deal
            setWeekDeal(details.week_deal);
          }
          setTimeout(() => {
            if (details.image_data) {
              printWithEpson(details.image_data, () => {
                fetch(process.env.REACT_APP_API_URL + "update-reedem-status", {
                  method: "POST",
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ qr_key: qr_data.data })
                }).then(res => {
                  navigate("/kiosk-start")
                }).catch(() => {
                  setScannerState(ScannerState.REDEEMFAILED);
                  resetTimer(4000)
                })
              });
            } else {
              console.error("Image Data Not Found!")
              setScannerState(ScannerState.FAILED);
              resetTimer(4000)
            }
          }, 2000)
        } else {
          setScannerState(ScannerState.FAILED);
          resetTimer(4000)
        }
      }).catch((error) => {
        setScannerState(ScannerState.REDEEMFAILED);
        resetTimer(4000)
        alert(error.response.message)
      })
  }, [printerState])


  const resetTimer = (time) => {
    timer.current && clearInterval(timer.current)
    timer.current = setTimeout(() => {
      navigate("/kiosk-start")
    }, time ?? 25000);
  }
  const clearTimer = () => {
    timer.current && clearInterval(timer.current)
  }


  // Success
  const onScanSuccess = useCallback((result) => {
    scanner?.current?.pause()
    // 🖨 Print the "result" to browser console.
    console.log(result);
    // ✅ Handle success.
    // 😎 You can do whatever you want with the scanned result.
    fetchBarCodeDetails(result)
    console.info({ printerState })
  }, [printerState]);

  // Fail
  const onScanFail = (err) => {
    // 🖨 Print the "err" to browser console.
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "user",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
        maxScansPerSecond: 6
      });
      if (process.env.NODE_ENV === 'development') {
        scanner?.current?.start()
          .catch((err) => {
            alert(err.toString())
            console.error(err)
          });
      }
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
        scanner?.current?.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (printerState === PrinterState.CONNECTED) {
      resetTimer()
      // 🚀 Start QR Scanner
      scanner?.current?.start()
        .catch((err) => {
          alert(err.toString())
          console.error(err)
        });
      return () => {
        clearTimer()
      }
    } else if (printerState === PrinterState.CONNECTING) {

    } else if (printerState === PrinterState.FAILED_TO_CONNECT || printerState === PrinterState.DISCONNECTED || printerState === PrinterState.PRINTING) {
      resetTimer()
      // 🚀 Start QR Scanner
      // scanner?.current?.start()
      //     .catch((err) => {
      //       alert(err.toString())
      //       console.error(err)
      //     });
      return () => {
        clearTimer()
      }
    }

  }, [printerState])

  const getScannerStateClass = useCallback(() => {
    if (scannerState === ScannerState.SUCCESS) {
      return "right-img"
    } else if (scannerState === ScannerState.FAILED) {
      return "wrong-img"
    } else {
      return ""
    }
  }, [scannerState])

  let imageName = "handscan.png"
  const isPrinterConnected = printerState === PrinterState.CONNECTED
  const isScanFailed = scannerState === ScannerState.FAILED || scannerState === ScannerState.REDEEMFAILED

  if (isPrinterConnected && !isScanFailed){
    imageName = "handscan-transparent.png"
  }else {
    imageName = "handscan.png"
  }
  console.info({st: (printerState !== PrinterState.PRINTING && printerState !== PrinterState.PRINTER_PAGE_EMPTY)})

  return (
    // <Link to="/kiosk-scan-sucqcess" >
    <div className='inner-section-scanner'>
      {/*<img src={BarcodeImage} />*/}
      <div className='container main-container'>
        {/* <div>
            <img className='arrow' src="/images/arrow.png"/>
          </div> */}

        {(printerState !== PrinterState.PRINTING && printerState !== PrinterState.PRINTER_PAGE_EMPTY) && <div className='heading-section text-center'>
          <img className="scan-logo-heading img-fluid" src="/images/logo3.png" />
        </div>}


        {printerState === PrinterState.PRINTING && <PrintPageHeader />}
        {printerState === PrinterState.PRINTER_PAGE_EMPTY && <PrinterEmptyHeader />}
        <div className="row scan-row">

          {printerState === PrinterState.PRINTING && <PrintPage />}
          {printerState === PrinterState.PRINTER_PAGE_EMPTY && <PrinterEmpty />}

          {(printerState !== PrinterState.PRINTING && printerState !== PrinterState.PRINTER_PAGE_EMPTY) &&  (<div className=" col-12">
            <div className="content-section">


              <div className={`position-relative scna-div ${getScannerStateClass()}`}>
                <img className="scan-logo" src={`/images/${imageName}`} alt="Mobile Phone" />
                <div className="scanner-div">

                  <div className="qr-reader">
                    {/* QR */}
                    <video ref={videoEl}></video>
                    <div ref={qrBoxEl} className="qr-box">
                      <img
                        src={QrFrame}
                        alt="Qr Frame"
                        width={256}
                        height={256}
                        className="qr-frame"
                      />
                    </div>

                  </div>
                </div>
              </div>
              {scannerState === ScannerState.IDEAL && <ScanProcess />}
              {scannerState === ScannerState.SUCCESS && weekDeal && <WeekDealDetails weekDeal={weekDeal} />}
              {scannerState === ScannerState.SUCCESS && prize &&
                <PrizeDetails prize={prize} />}
              {scannerState === ScannerState.FAILED && <ScanFailed />}
              {scannerState === ScannerState.REDEEMFAILED && <RedeemedFailed />}
            </div>

          </div>)
          }
        </div>
      </div>
      {/* {printerState === PrinterState.DISCONNECTED &&
          <p className="reconnect_button" onClick={() => { connectPrinter() }}> Reconnnect </p>}
       */}
      <div className={`printer_status`}>
        <div className={`print_status_dot ${printerState}`}></div>
      </div>
    </div>
    // </Link>
  );
};

export default KioskScan;