

export class LocalStorage {
    static GET_PAGES_COUNT(): number {
        return parseInt(process.env.REACT_APP_PRINTER_PAGES_COUNT);
    }
    static refillPages(): number {
        localStorage.setItem("printpages", String(this.GET_PAGES_COUNT()));
        return parseInt(localStorage.getItem("printpages"));
    }
      
    static getPages(): number {
        let pages: number | string = localStorage.getItem("printpages");
        if (!pages){
            pages = this.refillPages()
        }else {
            pages = parseInt(pages)
        }
        return  pages as number;
    }

    static printedPage(): number{
        const number = this.getPages();
        const remainingPages = number - 1;
        localStorage.setItem("printpages", String(remainingPages));
        return remainingPages;
    }

    static canPrintMore(): boolean {
        const CURREN_PAGES = this.getPages();
        return CURREN_PAGES >= 1;
    }
}
