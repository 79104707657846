
import React from 'react';
import { Link } from 'react-router-dom';
const KioskScanSuccess = () => {

  return (
    <div className="inner-section">
      <Link to="/kiosk-scan-fail" >
        <div className='container main-container'>

          {/* <div>
            <img className='arrow' src="/images/arrow.png" />
          </div> */}

          <div className='heading-section text-center'>
            <img className="scan-logo-heading img-fluid" src="/images/logo3.png" />
          </div>

          <div className="row w-100">

            <div className=" col-8 padding-zero position-relative right-img ">
              <img className="scan-logo" src="/images/handscan.png" alt="Mobile Phone" />
            </div>

            <div className="instructions padding-zero col-4 justify-content-center">
              <div className="success-content-sec">
                <div className="succ-text-sec">
                  <p className="text-succ">GEWELDIG!<br /> JE JUMBO DEAL IS</p>
                </div>
                {/* <div className="succ-img-div">
                  <img className="succ-card-img img-fluid" src="/images/item.png" alt="" />

                </div> */}

              </div>

            </div>

          </div>

        </div>
      </Link >
    </div>
  );
};

export default KioskScanSuccess;
