
import React from 'react';
import { Link } from 'react-router-dom';
const KioskScanFail = () => {

  return (
    <div className="inner-fail-sec">
      <Link to="/kiosk-ticket" >
        <div className="container main-container">


          {/* <div>
            <img className='arrow' src="/images/arrow.png" />
          </div> */}

          <div className='heading-section text-center'>
            <img className="scan-logo-heading img-fluid" src="/images/logo3.png" />
          </div>

          <div className="row w-100">

            <div className=" col-8 padding-zero position-relative wrong-img ">
              <img className="scan-logo fail-phone" src="/images/handscan.png" alt="Mobile Phone" />
            </div>

            <div className="instructions padding-zero col-4 justify-content-center">
              <div className="fail-content-sec">
                <div className="succ-text-sec">
                  <p className="text-succ">SORRY, JE CODE WERD REEDS GEBRUIKT </p>
                </div>

              </div>

            </div>

          </div>
        </div>

      </Link>
    </div>
  );
};

export default KioskScanFail;
