import React, { createContext, useRef, useState, useEffect, useCallback } from 'react';
import {LocalStorage} from "../LocalStorage";

// Define the PrinterState
export const PrinterState = {
    CONNECTING: "CONNECTING",
    CONNECTED: "CONNECTED",
    DISCONNECTED: "DISCONNECTED",
    FAILED_TO_CONNECT: "FAILED_TO_CONNECT",
    PRINTING: "PRINTING",
    IDEAL: "IDEAL",
    PRINTER_PAGE_EMPTY:"PRINTER_PAGE_EMPTY",
};

// Create the Printer Context
export const PrinterContext = createContext();

const PrinterProvider = ({ children }) => {
    const [printerState, setPrinterState] = useState(PrinterState.DISCONNECTED);
    const [statusMessage, setStatusMessage] = useState('');

    const tryReconnect = () => {
        setTimeout(() => {
            connectPrinter()
        }, 2000)
    }
    // Method to connect to the printer
    const connectPrinter = () => {
        if (!LocalStorage.canPrintMore()){
            setPrinterState(PrinterState.PRINTER_PAGE_EMPTY);
        }
        if (window.printer) {
            console.warn('Printer already connected');
            return;
        }
        const storedIpAddress = localStorage.getItem('printerIpAddress');
        const storedPort = localStorage.getItem('printerPort');
        // if (!ip || !port){
        //   alert("IP Not Found");
        //   return;
        // }

        var ePosDevice = new window.epson.ePOSDevice();
        // const ePosDevice = new window.epson.ePOSDevice();
        window.ePosDevice = ePosDevice;
        window.ePosDevice.connect(storedIpAddress, storedPort, function (data) {
            console.info({ data })
            if (data === 'OK' || data === 'SSL_CONNECT_OK') {
                window.ePosDevice.createDevice('local_printer', window.ePosDevice.DEVICE_TYPE_PRINTER,
                    { 'crypto': false, 'buffer': false }, cbCreateDevice_printer);
                function cbCreateDevice_printer(devobj, retcode) {
                    console.log({ devobj, retcode });
                    if (retcode == 'OK') {
                        window.ePosDevice.ondisconnect = OnDisconnect;
                        window.ePosDevice.onreconnecting = OnReconnecting;
                        window.ePosDevice.onreconnect = OnReconnect;

                        window.printer = devobj;
                        window.printer.timeout = 30000;
                        window.printer.onreceive = function (response) {

                            if (response.success) {
                                console.log('Print job completed successfully');
                                // Notify the user or update the UI accordingly
                            } else {
                                console.error('Print job failed:', response.code);
                                alert('Print job failed:', response.code);
                                // Handle the error and notify the user
                            }
                            LocalStorage.printedPage();
                            setTimeout(() => {
                                if (LocalStorage.canPrintMore()){
                                    setPrinterState(PrinterState.CONNECTED)
                                }else {
                                    setPrinterState(PrinterState.PRINTER_PAGE_EMPTY)
                                }
                                window.callback && window.callback(response)
                            }, 2000)
                        };
                        if (LocalStorage.canPrintMore()){
                            setPrinterState(PrinterState.CONNECTED)
                        }else {
                            setPrinterState(PrinterState.PRINTER_PAGE_EMPTY)
                        }
                        window.printer.oncoveropen = onStatusChange;
                    } else {
                        console.log({ retcode });
                        alert("Printer Connection Failed With Status Code" + retcode)
                        setPrinterState(PrinterState.FAILED_TO_CONNECT)
                        tryReconnect()
                    }
                }

            } else {
                setPrinterState(PrinterState.FAILED_TO_CONNECT)
                tryReconnect()
            }
        });

    }

    function OnReconnecting() {
        console.info("printer.onreconnecting");
        setPrinterState(PrinterState.CONNECTING)
    }

    function OnReconnect() {
        console.info("printer.onreconnect");
        setPrinterState(PrinterState.CONNECTED)
    }

    function OnDisconnect() {
        console.info("printer.ondisconnect");
        setPrinterState(PrinterState.DISCONNECTED)
    }
    // Load IP address and port from local storage
    useEffect(() => {
        const storedIpAddress = localStorage.getItem('printerIpAddress');
        const storedPort = localStorage.getItem('printerPort');

        if (storedIpAddress && storedPort) {
            // Automatically connect using the stored values
            setTimeout(() => {
                connectPrinter(storedIpAddress, storedPort);
            }, 1000)
        } else {
            // Prompt user for IP and port if not stored
            const ipAddress = window.prompt('Please enter the printer IP address:');
            const port = window.prompt('Please enter the printer port:');

            if (ipAddress && port) {
                localStorage.setItem('printerIpAddress', ipAddress);
                localStorage.setItem('printerPort', port);
                connectPrinter();
            }
        }



        return () => {
            if (window.printer) {
                try {
                    window.printer.disconnect();
                    console.log('Printer disconnected on unmount.');
                } catch (error) {
                    console.error('Error disconnecting printer on unmount:', error);
                }
                window.printer = null;
                setPrinterState(PrinterState.DISCONNECTED);
            }
        };
    }, []);

    // Method to disconnect the printer
    const disconnectPrinter = useCallback(() => {
        if (window.printer) {
            try {
                window.printer.disconnect();
                setPrinterState(PrinterState.DISCONNECTED);
                setStatusMessage('Printer disconnected');
                console.log('Printer disconnected successfully');
            } catch (error) {
                console.error('Error disconnecting printer:', error);
            }
            window.printer = null;
        }
    }, []);

    // Handle printer status changes
    const onStatusChange = (status) => {
        if (status.coverOpen) {
            setStatusMessage('The printer cover is open.');
        } else {
            setStatusMessage('The printer cover is closed.');
        }
        console.log('Status changed:', status);
    };

    // Handle printer disconnection
    const onDisconnect = () => {
        setPrinterState(PrinterState.DISCONNECTED);
        setStatusMessage('Printer has been disconnected. Please check the connection.');
        console.log('Printer has been disconnected.');
    };

    // Method to print text

    const printWithEpson = (data, callback) => {
        if (printerState !== PrinterState.CONNECTED) {
            console.error("Printer Not Connected!")
            return;
        }
        window.callback = callback;
        setPrinterState(PrinterState.PRINTING)
        setTimeout(() => {
            var img = new Image();
            img.src = data; // Replace with your image path
            img.onload = function () {
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);
                // var base64 = canvas.toDataURL('image/png');

                window.printer.addTextAlign(window.printer.ALIGN_CENTER);
                window.printer.addImage(context, 0, 0, canvas.width, canvas.height, window.printer.COLOR_1, window.printer.MODE_MONO);
                window.printer.addFeedLine(1);
                window.printer.addCut(window.printer.CUT_FEED);
                window.printer.send();
            };
        }, 2000)
    };


    return (
        <PrinterContext.Provider value={{ printerState, connectPrinter, disconnectPrinter, printWithEpson }}>
            {children}
        </PrinterContext.Provider>
    );
};

export default PrinterProvider;
