import React from 'react'
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <div className='main-wrapper'>
        <Outlet />
    </div>
  )
}

export default Layout