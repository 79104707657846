import React from "react";

export const ScanProcess = () => {
    return <div className="instructions-sec ">
        <div className="instructions-inner-sec">
            <div className="instruction">
                <div className="number">1</div>
                <div className="text-qr">SCAN</div>
            </div>
            <div className="instruction">
                <div className="number">2</div>
                <div className="text-qr">NEEM JE TICKET</div>
            </div>
            <div className="instruction">
                <div className="number">3</div>
                <div className="text-qr">TOON AAN DE KASSA</div>
            </div>
        </div>
    </div>
}