import React from "react";

export const PrizeDetails = ({prize}) => {
    return (
        <div className="instructions padding-zero col-4 justify-content-center">
            <div className="success-content-sec">
                <div className="succ-text-sec">
                    <p className="text-succ">GEWELDIG!<br/> JE JUMBO PRIZE IS {prize.title}</p>
                </div>
            </div>

        </div>
    )
}